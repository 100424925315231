<template lang="pug">
  .manage.view
    section.compact.file-src-form
      h1 Подготовить рассылку
      el-form(:model="file_form", @keydown.enter.native.prevent="tinyURL" :rules="file_form_rules" ref="form_form" label-position="top")
        el-form-item(prop="sender_name" label="Имя отправителя")
          el-input(v-model="file_form.sender_name" type="text" ref="sender_name")
        el-form-item(prop="batch")
          el-upload(ref="batch" :on-change="(file)=>{ submitUploadedFile(file) }" :auto-upload="false" :show-file-list="false" action="")
            el-button(slot="trigger" type="primary") Выберите файл рассылки
        div(v-if="file_url")
          a(:href="file_url" target="_blank") {{ file_url }}

    section.compact.file-src-form
      el-form(:model="url_form", @keydown.enter.native.prevent="tinyURL" :rules="url_form_rules" ref="url_form" label-position="top")
        el-form-item(prop="origin_url" label="Ссылка")
          el-input(v-model="url_form.origin_url" type="text" :readonly="url_form.locked" ref="origin_url")
        el-form-item
          template(v-if="!url_form.locked")
            el-button( @click="tinyURL", type="primary") Получить короткий аналог
          template(v-else)
            el-button(@click="resetUrlForm", type="primary") Сократить другую ссылку

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TINY_URL, TINY_BATCH_URLS } from "@/store/types"

const default_url_form = ()=>{
  return {
    locked: false,
    origin_url: null,
  }
}
const default_file_form = ()=>{
  return {
    locked: false,
    batch: null,
    sender_name: null,
  }
}

export default {
  data() {
    return {
      url_form: default_url_form(),
      url_form_rules: {
        origin_url: [
          { required: true, message: 'Укажите исходную ссылку', trigger: 'blur' },
        ],
      },
      file_form: default_file_form(),
      file_form_rules: {

      },
      file_url: '',
    }
  },
  computed: {
    ...mapGetters([
        'subscriber'
    ]),
  },
  created(){
    if(!this.subscriber.hasCompany()) this.$router.push({name: 'index'})
  },
  methods: {
    ...mapActions([
      TINY_URL,
      TINY_BATCH_URLS,
    ]),
    resetUrlForm(){
      this.url_form = default_url_form()
    },
    lockUrlForm(){
      this.url_form.locked = true
    },
    resetFileForm(){
      this.file_form = default_file_form()
    },
    lockFileForm(){
      this.file_form.locked = true
    },
    tinyURL(){
      this.$refs['url_form'].validate((valid) => {
        if (valid) {
          return this.TINY_URL(this.url_form).then((result)=>{
            this.lockUrlForm()

            this.url_form.origin_url = result.url

            this.$clipboard(this.url_form.origin_url)
            this.$root.pushAppMessages('Ссылка скопирована в буфер', 'success')
          }).catch((error)=>{
            if(error.response.data.errors){
              this.$root.pushAppMessages(error.response.data.errors.map((message)=>{
                return this.$t(message)
              }))
            }

            return Promise.reject(error)
          })
        } else return false
      })
    },
    submitUploadedFile(file) {
      return this.TINY_BATCH_URLS({
        sender_name: this.file_form.sender_name,
        file: file.raw,
      }).then((result)=>{
        this.file_url = result.url

        this.$clipboard(this.file_url)
        this.$root.pushAppMessages('Ссылка скопирована в буфер', 'success')
      }).catch((error)=>{
        if(error.response.data.errors){
          this.$root.pushAppMessages(error.response.data.errors.map((message)=>{
            return this.$t(message)
          }))
        }

        return Promise.reject(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.manage {
  .file-src-form {
    background: $color--secondary;
    color: $color--black;
  }
}
</style>